<template>
  <v-alert
    class="mt-5 "
    icon="mdi-alert-outline"
    outlined
    prominent
    dense
    v-if="pendingTransactions"
    type="warning"
  >
    <v-row align="center">
      <v-col class="grow">
        Existem {{ pendingTransactions }} lançamento{{ plural }} não
        confirmado{{ plural }}, totalizando R$
        {{ $format.decimal(sumPendingTransactions) }}
      </v-col>
      <v-col class="shrink">
        <v-btn
          @click="$emit('viewReleases')"
          class="text-capitalize"
          text
          plain
          :ripple="false"
          >Ver lançamento{{ plural }}</v-btn
        >
      </v-col>
    </v-row>
  </v-alert>
</template>

<script>
export default {
  data() {
    return {
      sumPendingTransactions: null,
      pendingTransactions: null,
    };
  },
  props: {
    account_id: {},
  },
  watch: {
    account_id: {
      deep: true,
      handler() {
        this.select();
      },
    },
  },
 
  computed: {
    plural() {
      if (this.pendingTransactions > 1) {
        return "s";
      }
      return "";
    },
  },
  methods: {
    async select() {
      await this.$http
        .index("account/account-pending-transactions", {
          account_id: this.account_id,
          status: ["pending"],
        })
        .then((response) => {
          this.pendingTransactions = response.pendingTransactions;

          this.sumPendingTransactions = this.$calc.sum(
            response.accountTransactions,
            "value_sum"
          );
        });
    },
  },
};
</script>

<style></style>
