<template>
  <v-dialog v-model="dialog" persistent max-width="800px">
    <v-card>
      <v-card-title>
        <h5>Editar Parcelas</h5>
      </v-card-title>

      <v-card-text>
        <v-row class="mb-6">
          <v-col cols="12">
            <template v-for="(saleBill, index) in saleBills">
              <v-row :key="index">
                <v-col cols="4">
                  <PaymentMethodsSelect v-model="saleBill.payment_method" />
                </v-col>
                <v-col cols="2">
                  <app-number-field label="Valor" v-model="saleBill.value" />
                </v-col>
                <template v-if="hasDueDate(saleBill.payment_method)">
                  <v-col cols="2">
                    <app-number-field
                      v-if="hasDueDate(saleBill.payment_method)"
                      label="Dias"
                      v-model="saleBill.due_days"
                      :precision="0"
                      @change="setDueDate(index)"
                    ></app-number-field>
                  </v-col>
                  <v-col cols="3">
                    <app-date-field
                      v-if="hasDueDate(saleBill.payment_method)"
                      label="Data"
                      v-model="saleBill.due_date"
                      @change="setDueDays(index)"
                    ></app-date-field>
                  </v-col>
                </template>
                <template v-if="hasInstallments(saleBill.payment_method)">
                  <v-col cols="2">
                    <InstallmentsSelect
                      v-model="saleBill.installments"
                      max-installments="24"
                    />
                  </v-col>
                </template>
                <v-col>
                  <v-btn icon @click="handleRemoveSaleBill(index)">
                    <v-icon small>mdi-close</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </template>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              class="text-capitalize"
              text
              color="info"
              @click="handleAddSaleBill()"
            >
              Adicionar Parcela
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          @click="dialog = false"
          text
          class="text-capitalize"
          color="secondary"
        >
          Cancelar
        </v-btn>
        <v-btn @click="handleSave()" class="text-capitalize" color="primary">
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import InstallmentsSelect from "../../payment-terms/ui/InstallmentsSelect.vue";
import CompanyChip from "../../company/sections/CompanyChip.vue";
import PaymentMethodsSelect from "../../payment-terms/ui/PaymentMethodsSelect.vue";
import paymentMethods from "../../../enums/paymentMethods";
import { addDays, format, parseISO, differenceInCalendarDays } from "date-fns";
import CustomerSpan from "../../customer/sections/CustomerSpan.vue";

export default {
  components: {
    CompanyChip,
    PaymentMethodsSelect,
    InstallmentsSelect,
    CustomerSpan,
  },

  data() {
    return {
      dialog: false,

      saleBills: [],

      paymentMethods: paymentMethods,
    };
  },

  methods: {
    async open(saleBills) {
      this.saleBills = JSON.parse(JSON.stringify(saleBills));

      this.dialog = true;
    },

    handleSave() {
      this.$emit("input", this.saleBills);

      this.dialog = false;
    },

    hasInstallments(paymentMethodValue) {
      if (paymentMethodValue) {
        return this.paymentMethods.find(
          (paymentMethod) => paymentMethod.value == paymentMethodValue
        ).hasInstallments;
      }
    },

    hasDueDate(paymentMethodValue) {
      if (paymentMethodValue) {
        return this.paymentMethods.find(
          (paymentMethod) => paymentMethod.value == paymentMethodValue
        ).hasDueDate;
      }
    },

    setDueDate(index) {
      let dueDays = this.saleBills[index].due_days;
      let dueDate = addDays(new Date(), dueDays);

      this.$set(
        this.saleBills[index],
        "due_date",
        format(dueDate, "yyyy-MM-dd")
      );
    },

    setDueDays(index) {
      let dueDate = parseISO(this.saleBills[index].due_date);

      let dueDays = differenceInCalendarDays(dueDate, new Date());

      this.$set(this.saleBills[index], "due_days", dueDays);
    },
    handleAddSaleBill() {
      this.saleBills.push({
        payment_method: null,
        value: 0,
        due_days: null,
        due_date: null,
        installments: null,
      });
    },

    handleRemoveSaleBill(index) {
      this.$delete(this.saleBills, index);
    },
  },
};
</script>

<style></style>
