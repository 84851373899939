var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"scrollable":"","persistent":"","max-width":"900px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{attrs:{"primary-title":""}},[_vm._v(" Vendas ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('app-text-search-field',{on:{"input":function($event){return _vm.select()}},model:{value:(_vm.searchParams.text),callback:function ($$v) {_vm.$set(_vm.searchParams, "text", $$v)},expression:"searchParams.text"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.sales.data,"items-per-page":-1,"dense":"","disable-sort":"","hide-default-footer":""},on:{"click:row":function($event){return _vm.handleReceivableSale($event.id)}},scopedSlots:_vm._u([{key:"item.salesman",fn:function(ref){
var item = ref.item;
return [(item.salesman_id)?_c('span',[_vm._v(" "+_vm._s(item.salesman.name)+" ")]):_vm._e()]}},{key:"item.customer",fn:function(ref){
var item = ref.item;
return [(item.customer_id != null)?[_vm._v(" "+_vm._s(item.customer.name)+" ")]:_vm._e(),(item.customer_id == null)?[_vm._v(" Consumidor Final ")]:_vm._e()]}},{key:"item.company",fn:function(ref){
var item = ref.item;
return [_c('CompanyChip',{attrs:{"company":item.company}})]}},{key:"item.saved_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.dateTimeBr(item.saved_at))+" ")]}},{key:"item.net_total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.decimal(item.net_total))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('SaleStatus',{attrs:{"value":item.status,"x-small":""}})]}}],null,true)}),_c('app-pagination',{attrs:{"data":_vm.sales},on:{"click":function($event){return _vm.select($event)}}})],1)],1)],1),_c('v-card-actions',[_c('v-btn',{staticClass:"text-capitalize",attrs:{"text":"","color":"secondary"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Voltar")])],1)],1),_c('ReceiveSaleForm',{ref:"ReceiveSaleForm",on:{"store":function($event){_vm.select(), _vm.$emit('store')}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }