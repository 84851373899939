<template>
  <v-dialog v-model="dialog" persistent scrollable max-width="800px">
    <v-card>
      <v-card-title class="d-flex justify-space-between">
        <div class="d-flex flex-column text--center">
          <span>
            <h5 :class="[titleColor, 'd-inline']">{{ title }}</h5>
            <app-shortcut-btn
              name="SaleView"
              :params="sale.id"
              @store="loadSale(sale.id)"
            />
          </span>
          <div>
            <SaleStatus x-small :value="sale.status" />
          </div>
        </div>

        <CompanyChip :company="sale.company" />
      </v-card-title>
      <v-divider />

      <v-card-text>
        <v-divider />
        <v-row class="my-2">
          <v-col>
            <h2><customer-span :customer="sale.customer" /></h2>
          </v-col>
        </v-row>
        <v-alert v-if="customerIsOutdated" border="left" type="error">
          Cliente desatualizado
        </v-alert>
        <v-row class="my-2">
          <v-col>
            <h3>{{ sale.salesman.name }}</h3>
          </v-col>
          <v-col class="text-right" v-if="sale.salesman.whatsapp_contact" l>
            <v-icon color="success">mdi-whatsapp</v-icon>
            {{ sale.salesman.whatsapp_contact }}
          </v-col>
        </v-row>
        <v-divider class="my-6" />

        <template v-if="sale.has_negotiation">
          <h4>
            Informações da Negociação
            <v-icon right>mdi-information</v-icon>
          </h4>

          <div class="d-flex flex-column">
            <span>
              <strong>Valor: </strong>
              {{ $format.moneyBr(sale.negotiation_value) }}
            </span>
            <span> <strong>OBS: </strong>{{ sale.negotiation_comments }} </span>
          </div>
          <v-divider class="my-6" />
        </template>

        <h3 class="my-2">Condições de pagamento</h3>
        <template v-if="!form.changedSaleBills">
          <template
            v-for="(sale_payment_term, index) in sale.sale_payment_terms"
          >
            <v-row :key="index">
              <v-col cols="4">
                {{ sale_payment_term.payment_term.name }}
              </v-col>
              <v-col class="text-right" cols="2">
                {{ $format.decimal(sale_payment_term.value) }}
              </v-col>
            </v-row>
          </template>
        </template>
        <template v-else>
          <span class="error--text">Condição de pagamento personalizada</span>
        </template>
        <v-divider class="my-6" />
        <v-row class="mb-6">
          <v-col cols="12">
            <h3 class="mb-6">Parcelas</h3>
            <template v-for="(saleBill, index) in form.sale_bills">
              <v-row :key="index">
                <v-col cols="4">
                  <PaymentMethodSpan
                    :payment-method="saleBill.payment_method"
                  />
                </v-col>
                <v-col cols="2">
                  <!-- <app-number-field label="Valor" v-model="saleBill.value" /> -->
                  {{ $format.decimal(saleBill.value) }}
                </v-col>
                <template v-if="hasDueDate(saleBill.payment_method)">
                  <v-col cols="2">
                    <!-- <app-number-field
                      v-if="hasDueDate(saleBill.payment_method)"
                      label="Dias"
                      v-model="saleBill.due_days"
                      :precision="0"
                      @change="setDueDate(index)"
                    ></app-number-field> -->
                    {{ saleBill.due_days }}
                  </v-col>
                  <v-col cols="3">
                    <!-- <app-date-field
                      v-if="hasDueDate(saleBill.payment_method)"
                      label="Data"
                      v-model="saleBill.due_date"
                      @change="setDueDays(index)"
                    ></app-date-field> -->
                    {{ $format.dateBr(saleBill.due_date) }}
                  </v-col>
                </template>
                <template v-if="hasInstallments(saleBill.payment_method)">
                  <v-col cols="2">
                    <!-- <InstallmentsSelect
                      v-model="saleBill.installments"
                      max-installments="24"
                    /> -->
                    {{ saleBill.installments }}x
                  </v-col>
                </template>
                <!-- <v-col>
                  <v-btn icon @click="handleRemoveSaleBill(index)">
                    <v-icon small>mdi-close</v-icon>
                  </v-btn>
                </v-col> -->
              </v-row>
            </template>
          </v-col>
        </v-row>
        <v-btn
          outlined
          color="primary"
          v-if="canEditParcels"
          @click="handleEditSaleBills"
          class="mr-2 text-capitalize"
        >
          <v-icon left>mdi-pencil</v-icon>
          Editar parcelas
        </v-btn>
      </v-card-text>
      <v-card-actions>
        <v-btn
          @click="dialog = false"
          text
          class="text-capitalize"
          color="secondary"
        >
          Cancelar
        </v-btn>
        <v-btn @click="handleSave()" class="text-capitalize" color="primary">
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
    <EditSaleBillsForm ref="EditSaleBillsForm" @input="setSaleBills($event)" />
  </v-dialog>
</template>

<script>
import PaymentMethodSpan from "../../payment-terms/ui/PaymentMethodSpan.vue";
import InstallmentsSelect from "../../payment-terms/ui/InstallmentsSelect.vue";
import CompanyChip from "../../company/sections/CompanyChip.vue";
import paymentMethods from "../../../enums/paymentMethods";
import { addDays, format, parseISO, differenceInCalendarDays } from "date-fns";
import CustomerSpan from "../../customer/sections/CustomerSpan.vue";
import EditSaleBillsForm from "./EditSaleBillsForm";
import SaleStatus from "@/components/sales/sections/SaleStatus.vue";

export default {
  components: {
    CompanyChip,
    InstallmentsSelect,
    CustomerSpan,
    EditSaleBillsForm,
    PaymentMethodSpan,
    SaleStatus,
  },

  data() {
    return {
      dialog: false,
      sale: {
        customer: {},
        company: {},
        operation: {},
        salesman: {
          company: {},
        },
      },

      form: {
        account_id: null,
        sale_id: null,
        sale_payment_terms: [],
        sale_bills: [],
        changedSaleBills: false,
      },

      paymentMethods: paymentMethods,
    };
  },

  computed: {
    title() {
      return `${this.sale.operation.description} #${this.sale.number}`;
    },

    titleColor() {
      if (this.sale.operation.movement_finance_type == "in") {
        return "primary--text";
      } else {
        return "error--text";
      }
    },

    canEditParcels() {
      return this.sale.operation.movement_finance_type == "in";
    },
    customerIsOutdated() {
      return this.sale.customer_id && this.sale.customer.is_outdated;
    },
  },

  methods: {
    async open(account_id, sale_id) {
      this.$loading.start();

      await this.loadSale(sale_id);

      this.setForm(account_id);

      this.$loading.finish();

      this.dialog = true;
    },

    async loadSale(sale_id) {
      await this.$http
        .show("sale/sale", sale_id)
        .then((response) => {
          this.sale = response.sale;
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    setForm(account_id) {
      this.form.account_id = account_id;
      this.form.sale_id = this.sale.id;
      this.form.sale_payment_terms = JSON.parse(
        JSON.stringify(this.sale.sale_payment_terms)
      );
      this.form.sale_bills = JSON.parse(JSON.stringify(this.sale.sale_bills));

      this.$nextTick(() => {
        this.form.changedSaleBills = false;
      });
    },

    handleSave() {
      this.$loading.start();

      this.$http
        .update("account/receive-sale", this.form.sale_id, this.form)
        .then((response) => {
          this.printSale();
          this.$loading.finish();
          this.dialog = false;
          this.$emit("store");
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    hasInstallments(paymentMethodValue) {
      return this.paymentMethods.find(
        (paymentMethod) => paymentMethod.value == paymentMethodValue
      ).hasInstallments;
    },

    hasDueDate(paymentMethodValue) {
      return this.paymentMethods.find(
        (paymentMethod) => paymentMethod.value == paymentMethodValue
      ).hasDueDate;
    },

    handleEditSaleBills() {
      this.$refs.EditSaleBillsForm.open(this.form.sale_bills);
    },

    setSaleBills(saleBills) {
      this.form.changedSaleBills = true;
      this.form.sale_bills = saleBills;
    },
    printSale() {
      window.open(`/vendas/venda/imprimir/${this.form.sale_id}`);
    },
  },
};
</script>

<style></style>
