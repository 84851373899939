<template>
  <v-card outlined>
    <v-card-title>
      <span>Receber Venda</span>
    </v-card-title>
    <v-card-text>
      <app-text-field
        v-model="form.number"
        solo
        flat
        :loading="isLoading"
        placeholder="Digite o número da venda..."
        @keypress.enter="searchSale"
      ></app-text-field>
      <v-alert v-if="error" class="mt-2" type="error" text>{{ error }}</v-alert>
    </v-card-text>
    <ReceiveSaleForm @store="reset(), $emit('store')" ref="ReceiveSaleForm" />
  </v-card>
</template>

<script>
import ReceiveSaleForm from "@/components/account/form/ReceiveSaleForm.vue";

export default {
  props: {
    account_id: {},
  },
  components: {
    ReceiveSaleForm,
  },
  data: () => ({
    form: {
      number: null,
    },

    sale: null,

    isLoading: false,

    error: null,
  }),

  methods: {
    searchSale() {
      this.error = null;
      this.isLoading = true;

      this.$http
        .index("sale/sale", this.form)
        .then((response) => {
          this.processResponse(response);

          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },

    reset() {
      this.form.number = null;
      this.sale = null;
      this.error = null;
    },

    processResponse(response) {
      if (response.sales.data.length !== 1) {
        this.form.number = null;
        this.error = "Venda não encontrada";
        return;
      }

      this.sale = response.sales.data[0];

      if (this.isAlreadyReceived(this.sale.status)) {
        this.form.number = null;
        this.error = "Venda já recebida";
        return;
      }

      if (this.validateStatus(this.sale.status)) {
        this.form.number = null;
        this.error = "Venda não pode ser recebida";
        return;
      }

      this.receiveSale(this.sale.id);
    },

    isAlreadyReceived(status) {
      return status == "sale";
    },

    validateStatus(status) {
      return !["draft", "proposal", "order"].includes(status);
    },

    receiveSale(id) {
      this.$refs.ReceiveSaleForm.open(this.account_id, id);
    },
  },
};
</script>

<style></style>
