<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
    scrollable
    eager
    origin="center"
  >
    <v-card>
      <v-card-title> {{ title }} </v-card-title>
      <v-card-text>
        <v-row class="mt-4">
          <v-col>
            <account-select
              label="Transferir Para"
              v-model="form.destination_account"
              :exclude="form.account"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            ><v-textarea
              hide-details="auto"
              outlined
              dense
              v-model="form.details"
              label="Detalhes"
              rows="3"
            />
          </v-col>
        </v-row>

        <v-row class="mt-8">
          <v-col>
            <app-text-field type="date" label="Data" v-model="form.date" />
          </v-col>
          <v-col>
            <app-number-field label="Valor" v-model="form.value" />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions
        class="justify-end py-4"
        style="background-color: #f5f5f5"
      >
        <v-btn
          @click="dialog = false"
          text
          class="text-capitalize"
          color="secondary"
          >Cancelar</v-btn
        >
        <v-btn @click="handleSave()" class="text-capitalize" color="primary"
          >Salvar</v-btn
        >
        <!-- <v-btn @click="dialog = false" class="text-capitalize" color="primary"
            >Salvar e continuar</v-btn
          > -->
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AccountSelect from "../ui/AccountSelect";
import format from "date-fns/format";

export default {
  components: { AccountSelect },

  data: () => ({
    dialog: false,

    title: "Transferência",

    form: {},

    baseForm: {
      account: null,
      destination_account: null,
      details: null,
      value: null,
      date: format(new Date(), "yyyy-MM-dd"),
    },
  }),

  computed: {
    accounts() {
      return this.$auth.state.user.accounts;
    },
  },

  methods: {
    open(account) {
      this.reset();

      this.form.account = account;

      this.dialog = true;
    },

    reset() {
      this.form = JSON.parse(JSON.stringify(this.baseForm));
    },

    async handleSave() {
      this.$store.commit("app/showLoading");
      await this.$http
        .store("account/transfer", this.form)
        .then((response) => {
          this.processSaved();
        })
        .catch((error) => {});
      this.$store.commit("app/hideLoading");
    },

    processSaved() {
      this.$emit("store");
      this.dialog = false;
    },
  },
};
</script>

<style>
</style>
