<template>
  <v-dialog v-model="dialog" scrollable persistent max-width="900px">
    <v-card>
      <v-card-title primary-title> Vendas </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="6">
            <app-text-search-field
              @input="select()"
              v-model="searchParams.text"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-data-table
              :headers="headers"
              :items="sales.data"
              :items-per-page="-1"
              dense
              disable-sort
              hide-default-footer
              @click:row="handleReceivableSale($event.id)"
            >
              <template v-slot:[`item.salesman`]="{ item }">
                <span v-if="item.salesman_id">
                  {{ item.salesman.name }}
                </span>
              </template>
              <template v-slot:[`item.customer`]="{ item }">
                <template v-if="item.customer_id != null">
                  {{ item.customer.name }}
                </template>
                <template v-if="item.customer_id == null">
                  Consumidor Final
                </template>
              </template>
              <template v-slot:[`item.company`]="{ item }">
                <CompanyChip :company="item.company" />
              </template>
              <template v-slot:[`item.saved_at`]="{ item }">
                {{ $format.dateTimeBr(item.saved_at) }}
              </template>
              <template v-slot:[`item.net_total`]="{ item }">
                {{ $format.decimal(item.net_total) }}
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <SaleStatus :value="item.status" x-small />
              </template>
            </v-data-table>
            <app-pagination @click="select($event)" :data="sales" />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn
          @click="dialog = false"
          text
          class="text-capitalize"
          color="secondary"
          >Voltar</v-btn
        >
      </v-card-actions>
    </v-card>
    <ReceiveSaleForm @store="select(), $emit('store')" ref="ReceiveSaleForm"/>
  </v-dialog>
</template>

<script>
import ReceiveSaleForm from "@/components/account/form/ReceiveSaleForm.vue";
import SaleStatus from "@/components/sales/sections/SaleStatus.vue";
import CompanyChip from "@/components/company/sections/CompanyChip.vue";
export default {
  components: {
    ReceiveSaleForm,
    CompanyChip,
    SaleStatus,
  },

  data() {
    return {
      dialog: false,
      account_id: null,

      headers: [
        { text: "Número", align: "center", value: "number" },
        { text: "Vendedor", align: "center", value: "salesman" },
        { text: "Cliente", align: "center", value: "customer" },
        { text: "Empresa", align: "center", value: "company" },
        { text: "Data", align: "center", value: "saved_at" },
        { text: "Valor", align: "center", value: "net_total" },
        { text: "Status", align: "center", value: "status" },
      ],

      searchParams: {
        status: ["waiting_approval", "order"],
        finance_status: 0,
        text: null,
      },

      sales: [],
    };
  },
 
  methods: {
    open(account_id) {
      this.account_id = account_id;
      this.select();
      this.dialog = true;
    },

    select(page) {
      this.searchParams.page = page;
      this.$loading.start();
      this.$http
        .index("sale/sale", this.searchParams)
        .then((response) => {
          this.sales = response.sales;
          this.$loading.finish();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    handleReceivableSale(sale_id) {
      this.$refs.ReceiveSaleForm.open(this.account_id, sale_id);
    },
  },
};
</script>

<style>
</style>