<template>
  <v-dialog v-model="dialog" persistent scrollable max-width="1000px">
    <v-card>
      <v-card-title>
        Lançamentos Pendentes
      </v-card-title>

      <v-card-text>
        <v-row class="my-3">
          <v-col cols="6"
            ><app-text-field
              v-model="searchParams.description"
              label="Pesquisa por Descrição"
          /></v-col>
          <v-col
            ><app-text-field
              type="date"
              v-model="searchParams.start_date"
              label="Data Inicial"
          /></v-col>
          <v-col
            ><app-text-field
              type="date"
              v-model="searchParams.end_date"
              label="Data Final"
          /></v-col>
        </v-row>

        <v-row>
          <v-col cols="8">
            <v-data-table
              :headers="headers"
              :items="accountTransactions.data"
              show-select
              v-model="form.transactions"
              :server-items-length="accountTransactions.total"
              hide-default-footer
            >
              <template v-slot:[`item.description`]="{ item }">
                <account-transaction-label :account-transaction="item" />
              </template>
              <template v-slot:[`item.date`]="{ item }">
                {{ $format.dateBr(item.date) }}
              </template>
              <template v-slot:[`item.value`]="{ item }">
                {{ $format.decimal(item.value) }}
              </template>
            </v-data-table>
          </v-col>
          <v-col>
            <v-card color="grey lighten-3 " elevation="0">
              <v-card-text>
                <v-row>
                  <v-col>
                    <h3>
                      Selecionados ({{ selectedCount }}):
                      <b>R$ {{ $format.decimal(sumValue) }}</b>
                    </h3>
                  </v-col>
                </v-row>
                <template v-if="selectedCount">
                  <v-row>
                    <v-col>
                      <app-text-field
                        v-model="form.date"
                        type="date"
                        label="Data"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-btn
                        class="text-capitalize"
                        block
                        @click="confirmTransactions()"
                        color="primary"
                        >Confirmar</v-btn
                      >
                    </v-col>
                  </v-row>
                </template>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions
        class="justify-end py-4"
        style="background-color: #f5f5f5"
      >
        <v-btn
          class="text-capitalize"
          text
          color="secondary"
          @click="dialog = false"
          >voltar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AccountTransactionLabel from "@/components/account/sections/AccountTransactionLabel.vue";

export default {
  components: {
    AccountTransactionLabel,
  },
  data() {
    return {
      form: {},
      baseForm: {
        date: null,
        transactions: [],
      },
      dialog: false,
      searchParams: {
        description: null,
        status: ["pending"],
        page: 1,
      },
      account_id: null,
      sumPendingTransactions: null,
      accountTransactions: {},
      headers: [
        { text: "Descrição", value: "description", align: "start" },
        {
          text: "Data",
          value: "date",
          sortable: false,
          align: "center",
          width: "15%",
        },
        // {
        //   text: "Meio Pg",
        //   value: "payment_method",
        //   sortable: false,
        //   align: "center",
        //   width: "12%",
        // },
        {
          text: "Valor",
          value: "value",
          sortable: false,
          align: "end",
          width: "12%",
        },
      ],
    };
  },
  watch: {
    searchParams: {
      deep: true,
      handler() {
        this.select();
      },
    },
  },
  mounted() {
    // this.open(1);
  },
  computed: {
    sumValue() {
      return this.form.transactions
        ? this.$calc.sum(this.form.transactions, "value")
        : null;
    },

    selectedCount() {
      return this.form.transactions ? this.form.transactions.length : null;
    },
  },

  methods: {
    open(account_id) {
      this.dialog = true;
      this.searchParams.account_id = account_id;
      this.select();
      this.reset();
    },
    reset() {
      this.form = JSON.parse(JSON.stringify(this.baseForm));
    },
    async confirmTransactions(page = 1) {
      this.$store.commit("app/showLoading");
      await this.$http
        .store("account/confirm-account-transaction", this.form)
        .then((response) => {
          this.dialog = false;
          this.$store.commit("app/hideLoading");
          this.$emit("store");
        })
        .catch((error) => {
          this.$store.commit("app/hideLoading");
        });
    },
    async select(page = 1) {
      this.searchParams.page = page;
      this.$store.commit("app/showLoading");
      await this.$http
        .index("account/account-transaction", this.searchParams)
        .then((response) => {
          this.accountTransactions = response.accountTransactions;
          this.sumPendingTransactions = response.sumPendingTransactions;

          this.$store.commit("app/hideLoading");
        })
        .catch((error) => {
          this.$store.commit("app/hideLoading");
        });
    },
  },
};
</script>

<style></style>
