<template>
  <v-dialog v-model="dialog" eager persistent max-width="600px" origin="center">
    <v-form ref="form" v-model="valid">
      <v-card>
        <v-card-title>
          <v-icon class="mr-2" :color="color" large>{{ icon }}</v-icon>
          <h4>{{ title }}</h4>
        </v-card-title>
        <v-card-text>
          <v-row class="mt-4">
            <v-col
              ><app-text-field label="Descrição" v-model="form.description" />
            </v-col>
          </v-row>
          <v-row class="mt-4">
            <v-col>
              <v-textarea
                hide-details="auto"
                outlined
                dense
                v-model="form.details"
                label="Detalhes"
                rows="3"
              />
            </v-col>
          </v-row>
          <v-row class="mt-4">
            <v-col>
              <account-plan-search v-model="form.account_plan" />
            </v-col>
          </v-row>
          <v-row class="mt-4">
            <v-col>
              <cost-center-search v-model="form.cost_center" />
            </v-col>
          </v-row>
          <v-row class="mt-4">
            <v-col>
              <app-date-field type="date" label="Data" v-model="form.date" />
            </v-col>
            <v-col>
              <app-number-field label="Valor" v-model="form.value" />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex justify-end">
              <v-chip-group mandatory v-model="form.status">
                <v-chip small color="primary" filter :value="'pending'"
                  >Pendente</v-chip
                >
                <v-chip small color="success" filter :value="'paid'"
                  >Pago</v-chip
                >
              </v-chip-group>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions
          class="justify-end py-4"
          style="background-color: #f5f5f5"
        >
          <v-btn
            @click="dialog = false"
            text
            class="text-capitalize"
            color="secondary"
            >Cancelar</v-btn
          >
          <v-btn @click="save()" class="text-capitalize" color="primary"
            >Salvar</v-btn
          >
          <v-btn @click="save(true)" class="text-capitalize" color="primary"
            >Salvar e continuar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import CompanySelect from "@/components/company/ui/CompanySelect.vue";
import AccountPlanSearch from "@/components/account-plan/ui/AccountPlanSearch";
import CostCenterSearch from "@/components/cost-center/ui/CostCenterSearch";
import BillPayableFormParcels from "@/components/bill-payable/forms/BillPayableFormParcels";
import format from "date-fns/format";

export default {
  components: {
    CompanySelect,
    AccountPlanSearch,
    CostCenterSearch,
    BillPayableFormParcels,
  },

  data() {
    return {
      valid: true,
      type: null,
      title: null,
      color: null,
      dialog: false,
      icon: null,
      account: {},
      form: {},

      baseForm: {
        account: null,
        account_plan: null,
        bank_statement_transaction : null,
        payment_method: "cash",
        cost_center: null,
        type: null,
        description: null,
        details : null,
        status: "paid",
        value: null,
        date: format(new Date(), "yyyy-MM-dd"),
      },
    };
  },

  methods: {
    async open(type, account, bankStatementTransaction = null) {
      this.reset();

      this.type = type;
      this.account = account;

      if (type == "input") {
        this.type = "in";
        this.title = "Nova Entrada no Caixa";
        this.color = "success";
        this.icon = "mdi-arrow-top-right";
      } else {
        this.type = "out";
        this.title = "Nova Saída no Caixa";
        this.color = "error";
        this.icon = "mdi-arrow-bottom-left";
      }

      if (bankStatementTransaction) {
        this.setBankStatementTransaction(bankStatementTransaction);
      }

      this.dialog = true;
    },

    reset() {
      this.form = JSON.parse(JSON.stringify(this.baseForm));
    },

    setBankStatementTransaction(bankStatementTransaction) {
      this.form.description = bankStatementTransaction.description;
      this.form.details = `Importado do Extrato:\n${bankStatementTransaction.description}`; 
      this.form.value = bankStatementTransaction.value;
      this.form.date = bankStatementTransaction.date;
      this.form.bank_statement_transaction = bankStatementTransaction;
    },

    async save(saveAndContinue = false) {
      this.form.account = this.account;
      this.form.type = this.type;

      this.$store.commit("app/showLoading");

      await this.$http
        .store("account/account-transaction", this.form)
        .then((response) => {
          this.processSaved(saveAndContinue);
        })
        .catch((error) => {});

      this.$store.commit("app/hideLoading");
    },

    processSaved(saveAndContinue) {
      this.$emit("store");
      if (saveAndContinue) {
        this.reset();
      } else {
        this.dialog = false;
      }
    },
  },
};
</script>


