<template>
  <div v-if="value">
    <template v-for="(paymentMethod, index) of paymentMethods">
      <div class="d-flex mt-2" :key="index">
        <div>
          {{ getPaymentMethodName(paymentMethod) }}
        </div>
        <div class="ml-auto">
          {{ $format.decimal(getTotal(paymentMethod)) }}
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import paymentMethodsEnums from "../../../enums/paymentMethods";

export default {
  props: {
    value: {
      default : null,
    },
  },

  data: () => ({
    paymentMethods: ["cash", "credit_card", "debit_card", "check", "bill"],
  }),

  methods: {
    getPaymentMethodName(paymentMethod) {
      return paymentMethodsEnums.find(
        (row) => row.value == paymentMethod
      ).label;
    },

    getTotal(paymentMethod) {
      let result = this.value.find(
        (row) => row.payment_method == paymentMethod
      )

      if (result) {
        return result.total;
      }

      return 0
    }
  },
};
</script>

<style></style>
